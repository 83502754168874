<template>
  <div class="loginPage" fill-height align-center justify-center>
    <div class="translate-div">
      <span class="micon translate-icon">language</span>
      <span class="translate-btn" :class="pdxLang == 'en' ? '' : 'none-active'" @click="changeLanguage('en')">EN</span>
      <span class="translate-btn" :class="pdxLang == 'ko' ? '' : 'none-active'" @click="changeLanguage('ko')">KO</span>
    </div>
    <div class="loginContainer">
      <img :src="require(`@/assets/images/logo${pineDx}.svg`)" :alt="`Pine-DX${pineDx}`">
      <div class="loginInput">
        <label><input type="text" placeholder="ID" v-model="loginId" @keyup.enter="login()" ref="loginIdRef"><i class="micon">person</i></label>
        <label><input type="password" placeholder="Password" v-model="loginPw" @keyup.enter="login()" ref="loginPwRef"><i class="micon">lock</i></label>
        <a class="btn loginBtn" @click="login()">{{ $t('page.login.button') }}</a>
      </div>
      <button class="solutionButton" @click="openInfoPopup()"><span><i class="micon">info</i>{{ $t('common.button.solutionInfo') }}</span></button>
    </div>
    <Loading fullscreen="true" size="32" :text="messages" :opacity="0.5" :isShow="isLoading"/>
    <!-- 솔루션 정보 팝업 -->
    <v-dialog v-model="infoPopupVisible">
        <InfoPopup @closeInfoPopup="closeInfoPopup" />
    </v-dialog>
  </div>
</template>

<script>
import API_LOGIN from '../../API/user/login';
import API_CHANGE_LANG from '@/API/user/changeLang';

export default {
  middleware: "guest",
  components: {
      InfoPopup: () => import('../../popup/infoPopup.vue'),
    },
  data() {
    return {
      loginId:'',
      loginPw:'',
      isLoading: false,
      pineDx: process.env.VUE_APP_PINE_DX,
      infoPopupVisible: false,
      pdxLang: localStorage.getItem('pdx-lang'),
    };
  },
  mounted() {
    this.initLocalStorage();
  },
  methods: {
    async login(){
      if(this.isLoading) return;
      if(this.loginId==''){
        this.showPopup(this.$t('page.login.msg.error.id'));
        return;
      }
      if(this.loginPw==''){
        this.showPopup(this.$t('page.login.msg.error.pw'));
        return;
      }
      this.isLoading = true;
      const startTime = Date.now();
      let res = await API_LOGIN.request(
          this.loginId,
          this.loginPw
      )
      const endTime = Date.now();
      if(endTime - startTime < 500)
        await new Promise(resolve => setTimeout(resolve, 500));
        this.isLoading = false;
      if (res.isSuccess) {
        this.changeLanguage(this.pdxLang);
        await this.$store.dispatch('auth/fetchData');
        const userRole = this.$store.getters['auth/userRole']
        if(userRole === "A") this.$router.push({ name: 'userManage' });
        else this.$router.push({ name: 'step1' });
      } else {
        this.showPopup(res.errorMsg);
      }
    },
    openInfoPopup() {
      this.infoPopupVisible = true;    
    },
    closeInfoPopup() {
      this.infoPopupVisible = false;
    },
    async changeLanguage(lang) {
        const res = await API_CHANGE_LANG.request(lang); // 서버에 언어 변경 요청 보내기
        this.pdxLang = lang;
        localStorage.setItem('pdx-lang', lang),
        this.$i18n.locale = lang; // 클라이언트에서 언어 변경
      },
    },
    computed: {
      messages() {
        return this.$t('page.login.msg.processing');
      }
    },
};
</script>

<style lang="scss" scoped>
.loginPage{
  height: 100vh;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.input-container {
  width: 400px;
  padding: 50px 20px;
}

.login-input {
  border: 2px solid rgba(223, 223, 223, 0.4);
  border-radius: 22px;
  margin-bottom: 12px;
}

.login-button {
  border-radius: 22px;
  margin-top: 24px;
  padding: 26px !important;
  font-size: 16px;
  height: auto;
  line-height: 1;
  color: #DFDFDF;
  font-weight: 600;
  border: 1px solid rgba(223, 223, 223, 0.3) !important;
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .v-input__slot {
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  input{
    color: rgba(255, 255, 255, 0.8);
  }
  i{
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
  }
  label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
}

::v-deep .v-text-field__slot {
  margin-left: 10px;
}

.solutionButton{color: rgba(255,255,255,0.8); margin-top: 25px; min-height: 24px; border-bottom: 1px solid transparent; transition: color .3s; }
.solutionButton:hover{color: #fff;}
.solutionButton span{display: flex; align-items: center;}
.solutionButton span i{margin-right: 5px;}

.translate-div{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height:86px;

  .translate-icon{
    color:#fff;
    opacity: 0.7;
    cursor: default;
    padding-bottom: 2px;
  }
  .translate-btn{
    color:#fff;
    margin-left: 10px;
    cursor: default;
    opacity: 1;
    border-bottom: 2px solid #fff;
  }
  .none-active{
    opacity: 0.6;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>